import React from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import ServiceHistoryPopup from "./component";

const mapStateToProps = ({
    config: {
        superAppConfig: {
            superAppServices: serviceInletsConfig
        } = {}
    }
}) => ({
    serviceInletsConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceHistoryPopup);
