import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import { NUMBER } from "../../../constants/app-constants";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import NewBadgeLogo from "./images/new-logo.png";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";
import useLink from "../../../hooks/use-link";

const ServiceInlets = ({ serviceInletsConfig = [] }) => {

    const { customPush } = useCustomHistoryOperations();

    const onServiceClick = async ({ path, gaEventName, isExternalLink }) => {
        if (!isExternalLink) {
            await customPush(path);
        }

        trackPlatformCustomEventsAEV2(b2cHomePageEvents[gaEventName]);
    };
    const { getLinkProps } = useLink();

    const nonPrimaryServices = serviceInletsConfig.filter(serviceInlets => !serviceInlets?.primary);
    const nonPrimayServiceCardWidth = NUMBER.HUNDRED / nonPrimaryServices?.length;
    const primaryServices = serviceInletsConfig.filter(serviceInlets => serviceInlets?.primary);

    return (
        <div styleName={"styles.serviceInletsWrapper"}>
            {primaryServices.map(serviceInlet => {
                const {
                    backgroundColor,
                    icon,
                    badgeBackgroundColor,
                    badgeTextColor,
                    badge,
                    title,
                    subTitle,
                    path,
                    gaEventName,
                    isExternalLink = false
                } = serviceInlet;

                const inlineStyle = {
                    background: backgroundColor
                };

                const inlineBadgeStyles = {
                    background: badgeBackgroundColor,
                    color: badgeTextColor
                };
                return (<a {...getLinkProps(`${path}`, { onClickHandler: onServiceClick.bind(null, { path, gaEventName, isExternalLink }), preventDefault: !isExternalLink })} styleName={"styles.primaryServiceInlet"} style={inlineStyle} key={title}>
                    <div styleName={"styles.titleWrapper"}>
                        <div styleName={"styles.title"}>{title}</div>
                        {badge && badge?.toLowerCase() !== "new" && <div styleName={"styles.badge"} style={inlineBadgeStyles}>{badge}</div>}
                        {badge && badge?.toLowerCase() === "new" && <img styleName={"styles.newLogo"} src={NewBadgeLogo} width={20} height={20} />}
                    </div>
                    <div styleName={"styles.subTitle"}>
                        <div>{subTitle}</div>
                        <div><img src={icon} /></div>
                    </div>
                </a>);
            })}
            <div styleName={"styles.nonPrimaryServiceInletWrapper"}>
                {nonPrimaryServices.map(serviceInlet => {
                    const {
                        backgroundColor,
                        icon,
                        title,
                        path,
                        gaEventName,
                        badge,
                        isExternalLink
                    } = serviceInlet;

                    const inlineStyle = {
                        background: backgroundColor
                    };

                    return (
                        <a {...getLinkProps(`${path}`, { onClickHandler: onServiceClick.bind(null, { path, gaEventName, isExternalLink }), preventDefault: !isExternalLink })} styleName={"styles.nonPrimaryServiceInlet"} key={title} style={{ ...inlineStyle, flex: `0 0 calc(${nonPrimayServiceCardWidth}% - 8px)`, maxWidth: `calc(${nonPrimayServiceCardWidth}% - 8px)` }}>
                            <img src={icon} />
                            <div styleName={"styles.title"} >{title}</div>
                            {badge && badge?.toLowerCase() === "new" && <img styleName={"styles.newLogo"} src={NewBadgeLogo} width={20} height={20} />}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

ServiceInlets.propTypes = {
    serviceInletsConfig: PropTypes.object
};

export default ServiceInlets;
